import React from 'react'
import Breadcrumbs from '@/Common/Breadcrumbs'
import Contact from '@/components/Home/Contact'
import Info from '@/components/Home/Contact/info'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'

const Cont = () => {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumbs
        subTitle={t('contact')}
        title={t('contact')}
        routes={[
          { to: '/', val: t('home') },
          { to: '#', val: t('contact') },
        ]}
      />
      <Info />
      <Contact />
    </>
  )
}
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Cont
