import React from 'react'
import Layout from '@/components/Layout'
import Footer from '@/components/Footer'
import Cont from '@/components/Contact'
import { useLocation } from '@reach/router'
import SEO from '@/components/seo'
import Header from '@/components/Header'
import { graphql } from 'gatsby'

const Contact = () => {
  return (
    <Layout>
      <SEO title="Contact" canonical={`${useLocation().host}`} />
      <Header />
      <Cont />
      <Footer />
    </Layout>
  )
}

export default Contact
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
