import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const Info = () => {
  return (
    <section className="section section-sm">
      <div className="container">
        <div className="layout-bordered">
          <div className="layout-bordered-item wow-outer">
            <div className="layout-bordered-item-inner wow slideInUp">
              <OutboundLink className="link-default" href="tel:+13058203200">
                <div className="icon icon-lg mdi mdi-phone text-primary"></div>
                <ul className="list-0">
                  <li>305-820-3200</li>
                </ul>
              </OutboundLink>
            </div>
          </div>
          <div className="layout-bordered-item wow-outer">
            <div className="layout-bordered-item-inner wow slideInUp">
              <OutboundLink className="link-default" href="mailto:info@avilasaccounting.com">
                <div className="icon icon-lg mdi mdi-email text-primary"></div>
                <span>info@avilasaccounting.com</span>
              </OutboundLink>
            </div>
          </div>
          <div className="layout-bordered-item wow-outer">
            <div className="layout-bordered-item-inner wow slideInUp">
              <OutboundLink
                className="link-default"
                href="https://goo.gl/maps/Um8ByqabeJ4irP2VA"
                target="_blank"
              >
                <div className="icon icon-lg mdi mdi-map-marker text-primary"></div>
                <div>8040 NW 95th St. Suite 334 Hialeah Gardens FL 33016 USA</div>
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Info
